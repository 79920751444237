import React from "react";
import "../blocks/ImageGrid.css";

import image1 from "../images/CarmenSupermarket-Concierto.jpg";
import image2 from "../images/CarmenSupermarket-Mesa.jpg";
import image3 from "../images/CarmenSupermarket.jpg";

function ImageGrid() {
  const images = [image1, image2, image3];
  return (
    <section id="facilities">
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-grid__item">
            <img src={image} alt={`Carmen Supermarket ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default ImageGrid;
