import React from "react";
import { useForm, ValidationError } from "@formspree/react";

import "../blocks/Contact.css";

function ContactForm() {
  const [state, handleSubmit] = useForm("xbjnwqak");

  if (state.succeeded) {
    return <p>¡Gracias por contactar y mucho Rock!</p>;
  }

  return (
    <div className="contact-container" id="contact">
      <h2 className="contact-title">Contacta</h2>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="contact-card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group contact-field">
                    <label htmlFor="name">Nombre:</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      className="form-control"
                    />
                    <ValidationError
                      prefix="Name"
                      field="name"
                      errors={state.errors}
                    />
                  </div>

                  <div className="form-group contact-field">
                    <label htmlFor="email">Email:</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="form-control"
                    />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                  </div>

                  <div className="form-group contact-field">
                    <label htmlFor="message">Mensaje:</label>
                    <textarea
                      id="message"
                      name="message"
                      className="form-control"
                    />
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                  </div>
                  <div className="form-group btn-block">
                    <button
                      type="submit"
                      disabled={state.submitting}
                      className="btn btn-primary "
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
