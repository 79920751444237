import logo from "../images/job_logos/logoCarmenSupermarket.png";
import logoinsta from "../images/job_logos/Instagram_Glyph_White.svg";

import { Link } from "react-router-dom";

import "../blocks/Header.css";

function Header() {
  return (
    <header className="header">
      <div className="header__container">
        <div className="header__logo">
          <Link to="/">
            <img className="header__logo-image" src={logo} alt="logo" />
          </Link>
          {/* <Link className="header__menu-list-item-brand" to="/">
            Carmen Supermarket
          </Link> */}
        </div>
        <div className="header__menu">
          <ul className="header__menu-list">
            <li className="header__menu-list-item">
              <Link className="header__menu-list-item-link" to="/plataformas">
                Escúchanos en tu plataforma preferida
              </Link>
            </li>
            <li className="header__menu-list-item">
              <Link className="header__menu-list-item-link" to="/contacta">
                Contacta
              </Link>
            </li>
            <li className="header__menu-list-item">
              <Link to="https://www.instagram.com/carmensupermarket">
                <img
                  className="header__logo-image-insta"
                  src={logoinsta}
                  alt="logo"
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
