import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import "../blocks/HeaderBurger.css";
import logoinsta from "../images/job_logos/Instagram_Glyph_White.svg";
import logomobile from "../images/job_logos/logoCarmenSupermarket.png";

import logo from "../images/job_logos/logoCarmenSupermarket.png";
import closeIcon from "../images/CloseIcon.svg";

function HeaderBurger() {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const sidebarRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <header className="burgerheader">
      <div className="burgerheader__container">
        <div className="burgerheader__logo">
          <Link to="/">
            <img className="header__logo-image" src={logomobile} alt="logo" />
          </Link>
        </div>
        <div className="burgerheader__burger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>

        {isOpen && (
          <>
            <div
              className={`burgerheader__sidebar ${
                isOpen ? "burgerheader__sidebar--open" : ""
              }`}
              ref={sidebarRef}
            >
              <button
                className="burgerheader__close"
                onClick={() => setIsOpen(false)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img src={closeIcon} alt="Close menu" />
              </button>
              <ul className="burgerheader__menu-list">
                <li className="burgerheader__menu-list-item">
                  <Link
                    className="burgerheader__menu-list-item"
                    to="/plataformas"
                  >
                    Escúchanos en tu plataforma preferida
                  </Link>
                </li>
                <li className="burgerheader__menu-list-item">
                  <Link
                    className="burgerheader__menu-list-item-link"
                    to="/contacta"
                  >
                    Contacta
                  </Link>
                </li>
                <li className="burgerheader__menu-list-item">
                  <Link to="https://www.instagram.com/carmensupermarket">
                    <img
                      className="burgerheader__logo-image-insta"
                      src={logoinsta}
                      alt="logo"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </header>
  );
}

export default HeaderBurger;
