import "../blocks/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__name-content">
        <p className="footer__text">Carmen Supermarket</p>
      </div>

      <p className="footer__text">carmensupermarket@gmail.com</p>
      <p className="footer__text">
        Desarrollado por Miguel González | {new Date().getFullYear()}
      </p>
    </footer>
  );
}

export default Footer;
