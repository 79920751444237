import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Header from "./Header";
import HeaderBurger from "./HeaderBurger";
import About from "./About";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import PageContent from "./PageContent";
import ContactForm from "./ContactForm";
import MusicPlatformsPage from "./MusicPlatformsPage";

import "../blocks/App.css";

function App() {
  return (
    <HelmetProvider>
      <main className="app-container">
        <Router>
          <ScrollToTop />
          <div className="page">
            <div className="page__content">
              <Header />
              <HeaderBurger />
              <Routes>
                <Route path="/" element={<PageContent />} />
                <Route path="/sobre-carmen-supermarket" element={<About />} />
                <Route path="/plataformas" element={<MusicPlatformsPage />} />
                <Route path="/contacta" element={<ContactForm />} />
              </Routes>
              <Footer />
            </div>
          </div>
        </Router>
      </main>
    </HelmetProvider>
  );
}

export default App;
