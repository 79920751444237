import React from "react";
import "../blocks/MusicPlatforms.css";

// Import your logo images
import spotifyLogo from "../images/platform_logos/Spotify_CarmenSupermarket.png";
import appleMusicLogo from "../images/platform_logos/AppleMusic_CarmenSupermarket.png";
import youtubeMusicLogo from "../images/platform_logos/Youtube_CarmenSupermarket.png";
import soundcloudLogo from "../images/platform_logos/Soundcloud_CarmenSupermarket.png";
import bandcamplogo from "../images/platform_logos/Bandcamp_CarmenSupermarket.png";
import amazonMusicLogo from "../images/platform_logos/AmazonMusic_CarmenSupermarket.png";

const MusicPlatforms = () => {
  const platforms = [
    {
      name: "Spotify",
      logo: spotifyLogo,
      link: "https://open.spotify.com/artist/4Ms17M4YF5EZfoUPEUCGny",
    },
    {
      name: "SoundCloud",
      logo: soundcloudLogo,
      link: "https://soundcloud.com/carmen-supermarket",
    },
    {
      name: "YouTube Music",
      logo: youtubeMusicLogo,
      link: "https://www.youtube.com/channel/UCY50bsTwlvFSzZZ723jbzAw",
    },
    {
      name: "Apple Music",
      logo: appleMusicLogo,
      link: "https://music.apple.com/es/artist/carmen-supermarket/1746821497",
    },
    {
      name: "Amazon Music",
      logo: amazonMusicLogo,
      link: "https://music.amazon.es/artists/B0D4C9G93X/carmen-supermarket",
    },
    {
      name: "Bandcamp",
      logo: bandcamplogo,
      link: "https://carmensupermarket.bandcamp.com/track/ramos-dos",
    },
  ];

  return (
    <div className="music-platforms-container">
      <h2>Escúchanos en tu plataforma preferida</h2>
      <div className="music-platforms-big">
        {platforms.map((platform) => (
          <a
            key={platform.name}
            href={platform.link}
            target="_blank"
            rel="noopener noreferrer"
            className="platform-link"
          >
            <img
              src={platform.logo}
              alt={platform.name}
              className="platform-logo-big"
            />
            <span className="platform-name">{platform.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default MusicPlatforms;
