import React from "react";
import SpotifyPlayer from "./SpotifyPlayer";
import MusicPlatforms from "./MusicPlatforms";

import "../blocks/About.css";

const About = () => {
  return (
    <div className="about" id="about">
      <div className="row">
        <div className="column title-container">
          <h1 className="about-title">
            ¿Quién es
            <br />
            Carmen?
          </h1>
        </div>
        <div className="column description-container">
          <p className="about-description">
            Carmen Supermarket es una banda de Pop-Rock de Mallorca que
            reivindica el sonido guitarrero y despreocupado de los 90. Graban su
            primer sencillo con dos temas en Tramuntana Estudis, bajo la
            producción de Jaume Gelabert.
          </p>
          <p className="about-description">
            Los componentes del grupo son Willy Julià a la guitarra, Miguel
            Ochogavía con el bajo y Miguel González a la batería.
          </p>
          {/* <p className="about-description">
            El nombre de la banda es un homenaje a todas las madres que apoyan
            incondicionalmente a sus hijos en sus sueños.
          </p> */}
        </div>
      </div>
      <MusicPlatforms />
      {/* <div className="spotify-player">
        <h2 className="about-subtitle">Escúchanos en Spotify</h2>
        <div className="card-group">
          <div className="spotify-card">
            <SpotifyPlayer spotifyUri="track/1KsmV8dOVFg8gt9quCFRdQ" />
          </div>
          <div className="spotify-card">
            <SpotifyPlayer spotifyUri="/track/3FwE1Vp8L5zdAiWq2CYRlv" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default About;
