import React from "react";
import emojis from "../emoji.json";
import "../blocks/Hero.css";

// Import your logo images
import spotifyLogo from "../images/platform_logos/Spotify_CarmenSupermarket.png";
import appleMusicLogo from "../images/platform_logos/AppleMusic_CarmenSupermarket.png";
import youtubeMusicLogo from "../images/platform_logos/Youtube_CarmenSupermarket.png";
import soundcloudLogo from "../images/platform_logos/Soundcloud_CarmenSupermarket.png";
import bandcamplogo from "../images/platform_logos/Bandcamp_CarmenSupermarket.png";
import amazonMusicLogo from "../images/platform_logos/AmazonMusic_CarmenSupermarket.png";

const HeroComponent = () => {
  const getRandomEmoji = () => {
    const randomIndex = Math.floor(Math.random() * emojis.length);
    return emojis[randomIndex].emoji;
  };

  return (
    <div id="home" className="hero-image-container">
      <div className="gradient-overlay"></div>
      <div className="hero-text">
        <h1>Carmen Supermarket</h1>
        <h2>Pop-Rock desde Mallorca {getRandomEmoji()}</h2>
        <div className="music-platforms">
          <a
            href="https://open.spotify.com/artist/4Ms17M4YF5EZfoUPEUCGny"
            target="_blank"
            rel="noopener noreferrer"
            alt="Spotify Carmen Supermarket"
          >
            <img src={spotifyLogo} alt="Spotify" />
          </a>
          <a
            href="https://soundcloud.com/carmen-supermarket"
            target="_blank"
            rel="noopener noreferrer"
            alt="SoundCloud Carmen Supermarket"
          >
            <img src={soundcloudLogo} alt="SoundCloud" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCY50bsTwlvFSzZZ723jbzAw"
            target="_blank"
            rel="noopener noreferrer"
            alt="YouTube Music Carmen Supermarket"
          >
            <img src={youtubeMusicLogo} alt="YouTube Music" />
          </a>
          <a
            href="https://music.apple.com/es/artist/carmen-supermarket/1746821497"
            target="_blank"
            rel="noopener noreferrer"
            alt="Apple Music Carmen Supermarket"
          >
            <img src={appleMusicLogo} alt="Apple Music" />
          </a>
          <a
            href="https://music.amazon.es/artists/B0D4C9G93X/carmen-supermarket"
            target="_blank"
            rel="noopener noreferrer"
            alt="Amazon Music Carmen Supermarket"
          >
            <img src={amazonMusicLogo} alt="Amazon Music" />
          </a>
          <a
            href="https://carmensupermarket.bandcamp.com/track/ramos-dos"
            target="_blank"
            rel="noopener noreferrer"
            alt="Bandcamp Carmen Supermarket"
          >
            <img src={bandcamplogo} alt="Bandcamp" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
