import React from "react";

import "../blocks/App.css";

import HeroComponent from "./HeroComponent";
import About from "./About";
import ContactForm from "./ContactForm";
import ImageGrid from "./ImageGrid";

function PageContent() {
  return (
    <>
      <HeroComponent />
      <main className="app-container">
        <About />
        <ImageGrid />
        <ContactForm />
      </main>
    </>
  );
}

export default PageContent;
